import React, { useContext, useEffect, useReducer } from 'react'
import {Link} from 'react-router-dom';
import {PageContext} from '../../../context/PageState';
import { Collapse } from 'reactstrap';

interface IState {
    nav_products : boolean;
    nav_solutions : boolean;
    nav_services : boolean;
    nav_about : boolean;
    nav_support : boolean;
}

const initialState : IState = {
    nav_products: false,
    nav_solutions: false,
    nav_services: false,
    nav_about: false,
    nav_support: false
}


const MobileNav = () => {
    const {nav, theme} = useContext(PageContext);
    const [state, dispatch] = useReducer(MenuReducer, initialState);
    const [isThemeDark, setIsThemeDark] = React.useState(theme.mode);

    useEffect(() => {
        setIsThemeDark(theme.mode);
    }, [theme.mode])

    const closeMenuDrawer = () => {
        dispatch('');
        nav.toggle('close');
    }

    return <div className='nav-container'>
        <nav className={nav.expanded
            ? 'active mobile'
            : 'mobile'}>
            {/* PRODUCTS */}
            <div className='tab'>
                <div onClick={()=>{state.nav_products? dispatch('') : dispatch('EXPAND_NAV_PRODUCTS');}} className="tab-header">
                        Products
                        <img
                            src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-${isThemeDark ? "white" : "black"}.svg`}
                            alt="arrow pointing up"
                        />
                </div>
                <Collapse className="subnav" isOpen={state.nav_products}>
                    <div className="subnav-wrap">
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link to="/devices" className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_thumb,w_200/v1605037792/partials/infinite-peripherals-scanning-devices.webp"
                                            alt="View all devices Imperea"
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">ALL DEVICES</span><br/>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link to="/accessories" className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_120,c_thumb/v1/partials/infinite-peripherals-accessories.webp"
                                            alt="View all devices Imperea"
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">ALL Accessories</span><br/>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Devices
                                </div>
                                <div className="subnav-links">
                                    <Link onClick={closeMenuDrawer} to="/products/quantumpay-solution">Payment</Link>
                                    <Link onClick={closeMenuDrawer} to="/products/scanning">Scanning</Link>
                                    <Link onClick={closeMenuDrawer} to="/products/printing">Printing</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            {/* SOLUTIONS */}
            <div className='tab'>
                <div onClick={()=>{state.nav_solutions? dispatch('') : dispatch('EXPAND_NAV_SOLUTIONS');}}  className="tab-header">

                    Solutions
                    <img

                        src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-${isThemeDark ? "white" : "black"}.svg`}
                        alt="arrow pointing up"
                    />
                </div>
                <Collapse className="subnav" isOpen={state.nav_solutions}>
                    <div className="subnav-wrap">
                        {/* <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link onClick={closeMenuDrawer} to="/software" className="nav-feature-link" >
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_300,c_thumb/software/quantum-logo-placeholder.webp"

                                            alt="quantum software suite"
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">Quantum Mobility Software Suite</span>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                        <div className="subnav-container">
                            <div className="subnav-section ">
                                <div className="subnav-header">
                                    Industries
                                </div>
                            <div className="subnav-links">
                                    <Link onClick={closeMenuDrawer} to="/industry/healthcare-solutions">Healthcare</Link>
                                    <Link onClick={closeMenuDrawer} to="/industry/hospitality-solutions">Hospitality</Link>
                                    <Link onClick={closeMenuDrawer} to="/industry/postal-solutions">Postal</Link>
                                    <Link onClick={closeMenuDrawer} to="/industry/retail-solutions">Retail</Link>
                                    <Link onClick={closeMenuDrawer} to="/industry/supply-chain-solutions">Supply Chain &amp; Logistics</Link>
                                    <Link onClick={closeMenuDrawer} to="/industry/travel-solutions">Travel</Link>
                                </div>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section ">
                                <div className="subnav-header">
                                    Partners
                                </div>
                                <div className="subnav-links">
                                    <Link onClick={closeMenuDrawer} to="/partners">All Partners</Link>
                                    <Link onClick={closeMenuDrawer} to="/applications">All Applications</Link>
                                </div>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Partners
                                </div>
                                <div className="subnav-links">
                                    <Link onClick={closeMenuDrawer} to="/products/quantumpay-solution">QuantumPay</Link>
                                    <Link onClick={closeMenuDrawer} to="/software">Quantum Mobility Software</Link>
                                    <div className="subnav-links__indented">
                                        <Link onClick={closeMenuDrawer} to="/software/quantum-iq">Quantum IQ</Link>
                                        <Link onClick={closeMenuDrawer} to="/software/quantum-wedge">Q Wedge</Link>
                                        <Link onClick={closeMenuDrawer} to="/software/quantum-browser">Q Browser</Link>
                                        <Link onClick={closeMenuDrawer} to="/software/quantum-vision">Q Vision</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            {/* SERVICES */}
            <div className='tab'>
                <div onClick={()=>{state.nav_services? dispatch('') : dispatch('EXPAND_NAV_SERVICES');}}   className="tab-header">
                        Services
                            <img
                                src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-${isThemeDark ? "white" : "black"}.svg`}
                                alt="arrow pointing up"
                            />
                </div>
                <Collapse className="subnav" isOpen={state.nav_services}>
                    <div className="subnav-wrap">
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <a
                                    href="https://warranty.ipcmobile.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_120,c_thumb/partials/warranty-registration-infinite-peripherals.webp"

                                            alt="IPCMobile support agent"
                                            className="nav-feature-img-thumbnail rounded" />
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">WARRANTY REGISTRATION</span><br />
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Services
                                </div>
                                <div className="subnav-links">

                                    <Link onClick={closeMenuDrawer} to="/technical-services">Technical Services</Link>
                                    <Link onClick={closeMenuDrawer} to="/warranty-services">Warranty Services</Link>
                                    <Link onClick={closeMenuDrawer} to="/software/quantum-services">Professional Services</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            {/* SUPPORT */}
            <div className='tab'>
                <div onClick={()=>{state.nav_support? dispatch('') : dispatch('EXPAND_NAV_SUPPORT');}}  className="tab-header">
                        Support
                            <img
                                src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-${isThemeDark ? "white" : "black"}.svg`}
                                alt="arrow pointing up"
                            />
                </div>
                <Collapse className="subnav" isOpen={state.nav_support}>
                    <div className="subnav-wrap">
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link onClick={closeMenuDrawer}
                                    to="/blog/why-the-last-mile-should-be-your-first-priority"
                                    className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_fill,g_face,w_60,h_60/v1/blogs/why-the-last-mile-should-be-your-first-priority.webp"
                                            alt="two warehouse workers one holding Linea Pro NexusConnect devices one looking at the camera the other scanning a shelf"
                                            className="nav-feature-img-thumbnail rounded" />
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">BLOG</span><br />
                                            Why the Last Mile Should Be Your First Priority
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Support
                                </div>
                                <div className="subnav-links">
                                    <a
                                        href="https://developer.ipcmobile.com"
                                        target="_blank"
                                        rel="noopener noreferrer">Developer Portal</a>
                                    <Link onClick={closeMenuDrawer} to="/support">Repair &amp; Warranty</Link>
                                    <Link onClick={closeMenuDrawer} to="/frequently-asked-questions">Frequently Asked Questions</Link>
                                    <Link onClick={closeMenuDrawer} to="https://ipcmobilehelp.zendesk.com/hc/en-us">Help Center</Link>
                                    <a href="https://utils.ipcmobile.com">Utility Tools</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            {/* ABOUT US */}
            <div className='tab'>
                <div onClick={()=>{state.nav_about? dispatch('') : dispatch('EXPAND_NAV_ABOUT');}}  className="tab-header">
                        About Us
                           <img
                            src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-${isThemeDark ? "white" : "black"}.svg`}
                            alt="arrow pointing up"
                        />
                </div>
                <Collapse className="subnav" isOpen={state.nav_about}>
                    <div className="subnav-wrap">
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link to="/blog/improve-medication-administration-for-increased-patient-demand" className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_120,c_thumb/v1/device-overview/imperea/imperea-overview-bcma.webp"
                                            alt="View BCMA Blog"
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">Enhancing BCMA</span><br/>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Our Story
                                </div>
                                <div className="subnav-links">
                                    <Link onClick={closeMenuDrawer} to="/about-us">About Us</Link>
                                    <Link onClick={closeMenuDrawer} to="/blog">Blog</Link>
                                    <Link onClick={closeMenuDrawer} to="/news">News</Link>
                                </div>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Resources
                                </div>
                                <div className="subnav-links">
                                    <Link onClick={closeMenuDrawer} to="/case-studies">Case Studies</Link>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.youtube.com/channel/UCBymBgFCpcM_S7f6Fu1LmEA">Videos</a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.cigna.com/legal/compliance/machine-readable-files">MRFs</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </nav>
    </div>
}

const MenuReducer = (state : IState, action : string) : IState => {
    switch (action) {
        case 'EXPAND_NAV_PRODUCTS':
            return {
                ...initialState,
                nav_products: true
            };
        case 'EXPAND_NAV_SOLUTIONS':
            return {
                ...initialState,
                nav_solutions: true
            };
        case 'EXPAND_NAV_SERVICES':
            return {
                ...initialState,
                nav_services: true
            };
        case 'EXPAND_NAV_ABOUT':
            return {
                ...initialState,
                nav_about: true
            };
        case 'EXPAND_NAV_SUPPORT':
            return {
                ...initialState,
                nav_support: true
            };
        default:
            return initialState
    }
}

export default MobileNav
