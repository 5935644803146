import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageContext } from '../../context/PageState';
import Hyperlink from '../../components/Hyperlink';

declare global {
    interface Window {
        generateSecuritySeal: (certification: string, format: string, position: string, type: string) => void;
    }
}

const FooterNav: React.FC = () => {
    const { theme } = useContext(PageContext);
    const [isThemeDark, setIsThemeDark] = useState(theme.mode);

    useEffect(() => {
        setIsThemeDark(theme.mode);
    }, [theme.mode])

    return <section className="footer-nav">
        <div className='container'>
            <div className="row footer-nav__content">
                <div className="footer-nav__branding col-12 col-lg-auto">
                    <img className="footer-nav__branding__logo"
                        src={`https://res.cloudinary.com/ipcmobile/image/upload/v1648060934/logos/ipcmobile-logo${isThemeDark ? "-inverted" : ""}.svg`}
                        alt="IPCMobile Logo"
                    />
                    <p className="footer-nav__branding__tagline">
                        Enterprise Mobility.<br />
                        Simpler.
                    </p>
                    <div className="footer-nav__social">
                        {/* LinkedIn Icon Link */}
                        <a
                            href={`${window.location.origin}/r/zVy80G`}
                            className="footer-nav__social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812646/icons/linkedin-${isThemeDark ? "white" : "black"}.svg`}
                                alt="IPCMobile LinkedIn icon"
                            />
                        </a>
                        {/* YouTube Icon Link */}
                        <a
                            href={`${window.location.origin}/r/21hJ6j`}
                            className="footer-nav__social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812646/icons/youtube-${isThemeDark ? "white" : "black"}.svg`}
                                alt="IPCMobile YouTube icon"
                            />
                        </a>
                        {/* TikTok Icon Link */}
                        <a
                            href={`${window.location.origin}/r/WguSgL`}
                            className="footer-nav__social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812635/icons/tiktok-${isThemeDark ? "white" : "black"}.svg`}
                                alt="IPCMobile TikTok icon"
                            />
                        </a>
                    </div>
                </div>
                <div className="col footer-nav__links col-lg-7 ms-lg-auto">
                    <div className="footer-nav__links__ipcmobile">
                        <h3 className="footer-nav__links__subheading">
                            IPCMobile
                        </h3>
                        <ul className="footer-nav__links__container">
                            <li>
                                <Hyperlink
                                    to="/about-us"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>About Us</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/contact/offices"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Our Offices</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/contact/offices"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Contact Us</span>
                                </Hyperlink>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-nav__links__solutions">
                        <h3 className="footer-nav__links__subheading">
                            Solutions
                        </h3>
                        <ul className="footer-nav__links__container">
                            <li>
                                <Hyperlink
                                    to="/software"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Quantum Mobility<br /> Software Suite</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/industry/healthcare-solutions"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Healthcare</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/industry/hospitality-solutions"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Hospitality</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/industry/postal-solutions"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Postal</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/industry/retail-solutions"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Retail</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/industry/supply-chain-solutions"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Supply Chain &amp; Logistics</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/industry/travel-solutions"
                                    newWindow={false}
                                    className="footer-nav__links__link">
                                    <span>Travel</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/partners"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Partners</span>
                                </Hyperlink>
                            </li>
                            <li>
                                <Hyperlink
                                    to="/applications"
                                    newWindow={false}
                                    className="footer-nav__links__link"
                                >
                                    <span>Applications</span>
                                </Hyperlink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row footer-nav__legal">
                <div className="col-12 mb-2 col-md-6 footer-nav__legal__copy">
                    <span className="d-block d-lg-inline-block">
                        Copyright &copy; <span>{new Date().getFullYear()}</span> IPCMobile Inc.&nbsp;
                    </span>
                    <span className="d-block d-lg-inline-block">
                        All rights reserved.
                    </span>
                    <span className="d-block d-lg-inline-block">
                        Google and Pixel are trademarks of Google LLC.
                    </span>
                </div>
                <div className="col col-md-6 footer-nav__legal__links">
                    <Link to="/privacy-policy" className="footer-nav__legal__link footer-nav__legal__privacy">
                        <span>Privacy Policy</span>
                    </Link>
                    <Link to="/cookie-policy" className="footer-nav__legal__link footer-nav__legal__cookies">
                        <span>Cookie Policy</span>
                    </Link>
                    <Link to="/terms-of-service" className="footer-nav__legal__link footer-nav__legal__terms">
                        <span>Terms of Service</span>
                    </Link>
                </div>
            </div>
        </div>
    </section>;


}


export default FooterNav;