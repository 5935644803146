import React, { Fragment } from 'react';
import { PageContext } from '../context/PageState';
import { Modal, ModalBody, Button } from 'reactstrap';
import SiteBanner from '../components/SiteBanner';
import Header from './Header';
import Footer from './Footer';
import CookieOptin from '../components/CookieOptin';

import PatentProtectedDisclaimer from '../components/PatentProtectedDisclaimer';
import Forms from '../components/Forms';

// interface ILayout {
// 	authors?: string[];
// 	children: React.ReactNode;
// 	cover?: string;
// 	datePublished?: string;
// 	dateModified?: string;
// 	dbLoaded: boolean;
// 	metadata: {
// 		title: string
// 	};
// 	slug?: string;
// 	subfooter?: string;
// }

const Layout: React.FC<any> = (props) => {

	const { modalForm, titleSuffix } = React.useContext(PageContext);

	React.useEffect(() => {

		if (props.metadata && props.metadata.title) {
			if (window.location.pathname === '/') {
				document.title = props.metadata.title;
			} else {
				document.title = titleSuffix(props.metadata.title);
			}
		}

	}, [props.metadata, props.title, modalForm, titleSuffix]);

	const externalCloseBtn = <Button close onClick={modalForm.toggle}>
		<span>
			<img
				className="icon"
				src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/close-x-white.svg"
				alt="Close X contact us form icon"
			/>
		</span>
	</Button>;

	return <Fragment>
		<SiteBanner />
		<Header
			metadata={props.metadata}
			dbLoaded={props.dbLoaded}
		/>
		<main>
			{props.children}
		</main>
		<PatentProtectedDisclaimer />
		<Footer subfooter={props.subfooter} />
		<CookieOptin />
		<Modal
			isOpen={modalForm.visible}
			toggle={modalForm.toggle}
			centered={true}
			external={externalCloseBtn}
			wrapClassName="form-modal"
		>
			<div className="modal-content">
				<ModalBody>
					<Forms />
				</ModalBody>
			</div>
		</Modal>
	</Fragment>
};

export default Layout;
