import React, {useReducer} from 'react'
import {Link} from 'react-router-dom';
import {PageContext} from '../../../context/PageState';

interface IState {
    nav_products : boolean;
    nav_solutions : boolean;
    nav_services : boolean;
    nav_about : boolean;
    nav_support : boolean;
}

const initialState : IState = {
    nav_products: false,
    nav_solutions: false,
    nav_services: false,
    nav_about: false,
    nav_support: false
}

const DesktopNav = () => {
    const {nav} = React.useContext(PageContext);
    const [state, dispatch] = useReducer(MenuReducer, initialState);

    const closeMenuDrawer = () => {
        dispatch(''); // return to initial state
        nav.toggle('close');
    }
    const openMenuDrawer = () => nav.toggle('open');

    return <div className="nav-container">
        <div className="nav-logo d-lg-none">
            <Link to="/" className="link">
                <img
                    src="https://res.cloudinary.com/ipcmobile/image/upload/v1570656283/logos/infinite-peripherals-logo-text.svg"
                    className="img-fluid"
                    alt="IPCMobile Logo text"/>
            </Link>
        </div>
        <div className="nav-seperator d-lg-none"></div>
        <nav className={nav.expanded
            ? 'active desktop'
            : 'desktop'}>
            {/* PRODUCTS */}
            <div
                className={`tab nav-products ${state.nav_products
                ? 'active'
                : ''}`}
                onMouseEnter={() => {
                dispatch('EXPAND_NAV_PRODUCTS');
                openMenuDrawer();
            }}
                onMouseLeave={closeMenuDrawer}>
                <div className="tab-header hasSubNav">
                    <span>
                        Products
                    </span>
                </div>
                <div className="subnav">
                    <div className="subnav-wrap" onMouseLeave={closeMenuDrawer}>
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link to="/devices" className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_thumb,w_200,h_100,g_face/v1605037792/partials/infinite-peripherals-scanning-devices.webp"
                                            srcSet="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_600,h_400,g_face,c_thumb/v1/partials/infinite-peripherals-scanning-devices.webp, 2x"
                                            alt="View all devices Imperea"
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">ALL DEVICES</span><br/>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link to="/accessories" className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_600,h_400,g_face,c_thumb/v1/partials/infinite-peripherals-accessories.webp"
                                            srcSet="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_600,h_400,g_face,c_thumb/v1/partials/infinite-peripherals-accessories.webp, 2x"
                                            alt="View all devices Imperea"
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">ALL Accessories</span><br/>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Devices
                                </div>
                                <div className="subnav-links">
                                    <Link to="/products/quantumpay-solution">Payment</Link>
                                    <Link to="/products/scanning">Scanning</Link>
                                    <Link to="/products/printing">Printing</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* SOLUTIONS */}
            <div
                className={`tab nav-solutions ${state.nav_solutions
                ? 'active'
                : ''}`}
                onMouseEnter={() => {
                dispatch('EXPAND_NAV_SOLUTIONS');
                openMenuDrawer();
            }}
                onMouseLeave={closeMenuDrawer}>
                <div className="tab-header hasSubNav">
                    <span>
                        Solutions
                    </span>
                </div>
                <div className="subnav">
                    <div className="subnav-wrap" onMouseLeave={closeMenuDrawer}>
                        {/* <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link to="/software" className="nav-feature-link" onClick={closeMenuDrawer}>
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_300,g_face,c_thumb/software/quantum-logo-placeholder.webp"
                                            srcSet="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_600,h_450,g_face,c_thumb/software/quantum-logo-placeholder.webp, 2x"
                                            alt=""
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">Quantum Mobility Software Suite</span>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                        <div className="subnav-container">
                            <div className="subnav-section ">
                                <div className="subnav-header">
                                    Industries
                                </div>
                                <div className="subnav-links">
                                    <Link to="/industry/healthcare-solutions" onClick={closeMenuDrawer}>Healthcare</Link>
                                    <Link to="/industry/hospitality-solutions" onClick={closeMenuDrawer}>Hospitality</Link>
                                    <Link to="/industry/postal-solutions" onClick={closeMenuDrawer}>Postal</Link>
                                    <Link to="/industry/retail-solutions" onClick={closeMenuDrawer}>Retail</Link>
                                    <Link to="/industry/supply-chain-solutions">Supply Chain &amp; Logistics</Link>
                                    <Link to="/industry/travel-solutions">Travel</Link>
                                </div>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section ">
                                <div className="subnav-header">
                                    Partners
                                </div>
                                <div className="subnav-links">
                                    <Link to="/partners">All Partners</Link>
                                    <Link to="/applications">All Applications</Link>
                                </div>
                            </div>
                        </div>
                        <div className="subnav-container no-border-right">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Solutions
                                </div>
                                <div className="subnav-links">
                                    <Link to="/products/quantumpay-solution">QuantumPay</Link>
                                    <Link to="/software">Quantum Mobility Software</Link>
                                    <div className="subnav-links__indented">
                                        <Link to="/software/quantum-iq">Quantum IQ</Link>
                                        <Link to="/software/quantum-wedge">Q Wedge</Link>
                                        <Link to="/software/quantum-browser">Q Browser</Link>
                                        <Link to="/software/quantum-vision">Q Vision</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SERVICES */}
            <div
                className={`tab nav-services ${state.nav_services
                ? 'active'
                : ''}`}
                onMouseEnter={() => {
                dispatch('EXPAND_NAV_SERVICES');
                openMenuDrawer();
            }}
                onMouseLeave={closeMenuDrawer}>
                <div className="tab-header hasSubNav">
                    <span>
                        Services
                    </span>
                </div>
                <div className="subnav">
                    <div className="subnav-wrap" onMouseLeave={closeMenuDrawer}>
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <a
                                    href="https://warranty.ipcmobile.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_300,g_face,c_thumb/partials/warranty-registration-infinite-peripherals.webp"
                                            srcSet="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_600,h_400,g_face,c_thumb/partials/warranty-registration-infinite-peripherals.webp, 2x"
                                            alt="IPCMobile support agent"
                                            className="nav-feature-img-thumbnail rounded" />
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">WARRANTY REGISTRATION</span><br />
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Services
                                </div>
                                <div className="subnav-links">
                                   <Link onClick={closeMenuDrawer} to="/technical-services">Technical Services</Link>
                                    <Link onClick={closeMenuDrawer} to="/warranty-services">Warranty Services</Link>
                                   <Link onClick={closeMenuDrawer} to="/software/quantum-services">Professional Services</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SUPPORT */}
            <div
                className={`tab nav-support ${state.nav_support
                ? 'active'
                : ''}`}
                onMouseEnter={() => {
                dispatch('EXPAND_NAV_SUPPORT');
                openMenuDrawer();
            }}
                onMouseLeave={closeMenuDrawer}>
                <div className="tab-header hasSubNav">
                    <span>
                        Support
                    </span>
                </div>
                <div className="subnav">
                    <div className="subnav-wrap" onMouseLeave={closeMenuDrawer}>
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link
                                    to="/blog/why-the-last-mile-should-be-your-first-priority"
                                    className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_fill,w_300,g_face/v1/blogs/why-the-last-mile-should-be-your-first-priority.webp"
                                            srcSet="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_fill,w_600,h_400,g_face/v1/blogs/why-the-last-mile-should-be-your-first-priority.webp, 2x"
                                            alt="warranty services"
                                            className="nav-feature-img-thumbnail rounded" />
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">BLOG</span><br />
                                            Why the Last Mile Should Be Your First Priority
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Support
                                </div>
                                <div className="subnav-links">
                                    <a
                                        href="https://developer.ipcmobile.com"
                                        target="_blank"
                                        rel="noopener noreferrer">Developer Portal</a>
                                    <Link to="/support">Repair &amp; Warranty</Link>
                                    <Link to="/frequently-asked-questions">Frequently Asked Questions</Link>
                                    <Link to="https://ipcmobilehelp.zendesk.com/hc/en-us">Help Center</Link>
                                    <a href="https://utils.ipcmobile.com">Utility Tools</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ABOUT US */}
            <div
                className={`tab nav-about ${state.nav_about
                ? 'active'
                : ''}`}
                onMouseEnter={() => {
                dispatch('EXPAND_NAV_ABOUT');
                openMenuDrawer();
            }}
                onMouseLeave={closeMenuDrawer}>
                <div className="tab-header hasSubNav">
                    <span>
                        About Us
                    </span>
                </div>
                <div className="subnav">
                    <div className="subnav-wrap" onMouseLeave={closeMenuDrawer}>
                        <div className="nav-feature">
                            <div className="nav-feature-container">
                                <Link to="/blog/improve-medication-administration-for-increased-patient-demand" className="nav-feature-link">
                                    <div className="nav-feature-img-wrap">
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_300,g_face,c_thumb/v1/device-overview/imperea/imperea-overview-bcma.webp"
                                            srcSet="https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,w_600,h_400,g_face,c_thumb/v1/device-overview/imperea/imperea-overview-bcma.webp, 2x"
                                            alt="View BCMA Blog"
                                            className="nav-feature-img-thumbnail rounded"/>
                                    </div>
                                    <div className="nav-feature-text-wrap">
                                        <span className="nav-feature-text">
                                            <span className="nav-feature-text-title">Enhancing BCMA</span><br/>
                                        </span>
                                        <img
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg"
                                            className="icon arrow right"
                                            alt="Learn more about blog"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Our Story
                                </div>
                                <div className="subnav-links">
                                    <Link to="/about-us">About Us</Link>
                                    <Link to="/blog">Blog</Link>
                                    <Link to="/news">News</Link>
                                </div>
                            </div>
                        </div>
                        <div className="subnav-container">
                            <div className="subnav-section">
                                <div className="subnav-header">
                                    Resources
                                </div>
                                <div className="subnav-links">
                                    <Link to="/case-studies">Case Studies</Link>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.youtube.com/channel/UCBymBgFCpcM_S7f6Fu1LmEA">Videos</a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.cigna.com/legal/compliance/machine-readable-files">MRFs</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>;
}

const MenuReducer = (state : IState, action : string) : IState => {
    switch (action) {
        case 'EXPAND_NAV_PRODUCTS':
            return {
                ...initialState,
                nav_products: true
            };
        case 'EXPAND_NAV_SOLUTIONS':
            return {
                ...initialState,
                nav_solutions: true
            };
        case 'EXPAND_NAV_SERVICES':
            return {
                ...initialState,
                nav_services: true
            };
        case 'EXPAND_NAV_ABOUT':
            return {
                ...initialState,
                nav_about: true
            };
        case 'EXPAND_NAV_SUPPORT':
            return {
                ...initialState,
                nav_support: true
            };
        default:
            return initialState
    }
}
export default DesktopNav
