import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { AuthContext, db } from '../../context/AuthState';
import { PageContext } from '../../context/PageState';
import { doc, getDoc } from "firebase/firestore";
import qs from 'qs';
import { IAction, IDeviceSearch } from "../../types";
import Layout from '../../partials/Layout';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Panel,
  RefinementList,
  ClearRefinements,
  Pagination,
  HitsPerPage,
  connectStateResults
} from 'react-instantsearch-dom';
import { orderBy } from 'lodash';

import './index.scss';
import CustomHits from './Components/CustomHits';
import CustomInitHits from './Components/CustomInitHits';
import CustomSearchBox from '../../components/InstantSearch/CustomSearchBox';
import CustomRefinements from '../../components/InstantSearch/CustomRefinements';
import CustomHierarchicalMenu from './Components/CustomHierarchicalMenu';
import PromoBanner from './Components/PromoBanner';

const searchClient = algoliasearch(
  `${process.env.REACT_APP_INSTANTSEARCH_APP_ID}`,
  `${process.env.REACT_APP_INSTANTSEARCH_SEARCH_ONLY_KEY}`
);

const DEBOUNCE_TIME:number = 400;

const initialState: IDeviceSearch = {
  dbLoaded: false,
  metadata: {},
  sections: {},
  subfooter: {}
}

const isDefaultRoute = (state:any) => !state.query &&
  state.page === 1 &&
  (
    state.refinementList &&
    (
      state.refinementList.OS &&
      state.refinementList.OS.length === 0
    ) &&
    (
      state.refinementList.functionality &&
      state.refinementList.functionality.length === 0
    ) &&
    (
      state.refinementList.features &&
      state.refinementList.features.length === 0
    ) &&
    (
      state.refinementList.industry &&
      state.refinementList.industry.length === 0
    )
  ) &&
  (
    state.hierarchicalMenu &&
    state.hierarchicalMenu["categories.lvl0"] === ""
  ) &&
  (
    state.menu &&
    !state.menu.categories
  );

function getCategorySlug(name: string) {
  return name
    .split(' ')
    .map(encodeURIComponent)
    .join('+');
}

function getCategoryName(slug: string) {
  return slug
    .split('+')
    .map(decodeURIComponent)
    .join(' ');
}

function parseParams(params: string) {
  params = params.replace(/\+/g, "%20");
  const patt1 = new RegExp(/\+/g);
  if (patt1.test(params)) {
    parseParams(params);
  }
  return params;
}

function stringifyParams(params: string) {
  params = params.replace(/%2C/g,'~').replace(/%20/g,"+");
  const patt1 = new RegExp(/%2C/g);
  const patt2 = new RegExp(/%20/g);
  if (patt1.test(params) && patt2.test(params)) {
    stringifyParams(params);
  }
  return params;
}

const createURL = (state:any) => {
  if (isDefaultRoute(state)) {
    return `/devices`;
  }
  const categoryPath = state.menu.categories
    ? `${getCategorySlug(state.menu.categories)}/`
    : '';
  const queryParameters: {} | any = {};
  if (state.query) {
    queryParameters.query = encodeURIComponent(state.query);
  }
  if (state.page !== 1) {
    queryParameters.page = state.page;
  }
  if (state.hierarchicalMenu !== undefined && state.hierarchicalMenu['categories.lvl0'] !== undefined && state.hierarchicalMenu['categories.lvl0'].length) {
    if (state.hierarchicalMenu['categories.lvl0'] !== 'Device Agnostic') {
      let hierarchicalMenuCat = state.hierarchicalMenu['categories.lvl0'].toString();
      hierarchicalMenuCat = hierarchicalMenuCat.substring(hierarchicalMenuCat.indexOf('>') + 1, hierarchicalMenuCat.length).trim();
      queryParameters.compatibility = hierarchicalMenuCat;
    }
  }
  if (state.refinementList !== undefined && state.refinementList.OS !== undefined && state.refinementList.OS.length) {
    queryParameters.platform = state.refinementList.OS;
  }
  if (state.refinementList !== undefined && state.refinementList.functionality !== undefined && state.refinementList.functionality.length) {
    queryParameters.function = state.refinementList.functionality;
  }
  if (state.refinementList !== undefined && state.refinementList.features !== undefined && state.refinementList.features.length) {
    queryParameters.features = state.refinementList.features;
  }
  if (state.refinementList !== undefined && state.refinementList.industry !== undefined && state.refinementList.industry.length) {
    queryParameters.industry = state.refinementList.industry;
  }
  const queryString = stringifyParams(qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'comma'
  }));

  return `/devices${categoryPath}${queryString}`;
};

const urlToSearchState = (location: any) => {

  const pathnameMatches: any = location.pathname.match(/devices\/(.*?)\/?$/);

  const category: any = getCategoryName(
    (pathnameMatches && pathnameMatches[1]) || ''
  );

  let data: any = qs.parse(parseParams(location.search.slice(1)));

  const query = data.query || '';
  const page = data.page || 1;

  // `qs` does not return an array when there's a single value.
  const platform: any = Array.isArray(data.platform) ? data.platform : [data.platform].filter(Boolean);
  const functionality: any = Array.isArray(data.function) ? data.function : [data.function].filter(Boolean);
  const compatibility: any = Array.isArray(data.compatibility) ? data.compatibility : [data.compatibility].filter(Boolean);
  const features: any = Array.isArray(data.features) ? data.features : [data.features].filter(Boolean);
  const industry: any = Array.isArray(data.industry) ? data.industry : [data.industry].filter(Boolean);


  const deviceProfile = compatibility.toString();

  let hierarchicalCat: string;
  switch (deviceProfile.split(' ')[0]) {
    case 'iPhone':
      hierarchicalCat = `iPhone > ${deviceProfile}`;
      break;
    case 'iPad':
      hierarchicalCat = `iPad > ${deviceProfile}`;
      break;
    case 'iPod':
      hierarchicalCat = `iPod touch > ${deviceProfile}`;
      break;

    default:
      hierarchicalCat = ""
      break;
  }

  return {
    query: decodeURIComponent(query.toString()),
    page,
    hierarchicalMenu: {
      "categories.lvl0": hierarchicalCat
    },
    menu: {
      categories: decodeURIComponent(category),
    },
    refinementList: {
      OS: platform.map(decodeURIComponent),
      functionality: functionality.map(decodeURIComponent),
      compatibility: compatibility.map(decodeURIComponent),
      features: features.map(decodeURIComponent),
      industry: industry.map(decodeURIComponent),
    }
  };
};

const searchStateToUrl = (searchState:any) => {
  return searchState ? createURL(searchState) : '';
}

const Results = connectStateResults(
  ({ searchState, searchResults, children }: any) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <p className="my-lg-5 pt-3 pb-5">
        No results have been found for {searchState.query}.
      </p>
    )
);

const DeviceSearch: React.FC<any> = (props) => {

  const {user} = React.useContext(AuthContext);
  const {titleSuffix, errors, theme} = React.useContext(PageContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [ isDefaultView, setIsDefaultView ] = React.useState<boolean>(true);
  const [searchState, setSearchState] = React.useState<any>(urlToSearchState(window.location));
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);
  const [debouncedSetState, setDebouncedSetState] = React.useState<any>(null);
  const [expandMobileFilters, setExpandMobileFilters] = React.useState<boolean>(false);
  const [expandMobileMenu, setExpandMobileMenu] = React.useState<boolean>(false);

  React.useEffect(() => {

    if (!user) return

    const getPageData = async () => {

      const docRef = doc(db, "pages/device-search");
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) return;

      let data = docSnap.data();

      dispatch({
        type: 'SET_PAGE_STATE',
        payload: {
          metadata: data.metadata,
          sections: data.sections,
          subfooter: data.subfooter
        }
      })

    }

    getPageData();

    if (isFirstRender) {
      setIsDefaultView(isDefaultRoute(searchState));
      setIsFirstRender(false);
    }

    window.gtag('event', 'page_view', {
      page_title: 'Devices',
      page_location: window.location.pathname
    });

    document.body.className ="layout products instant-search devices";

  }, [props, isFirstRender, setIsFirstRender, searchState, titleSuffix, errors, user]);

  const onSearchStateChange = (updatedSearchState:any) => {
    clearTimeout(debouncedSetState);
    setIsDefaultView((isDefaultRoute(updatedSearchState) === false) ? false : true);

    setDebouncedSetState(
      setTimeout(() => {
        window.history.pushState(
          updatedSearchState,
          'Devices - IPCMobile',
          searchStateToUrl(updatedSearchState)
        );
      }, DEBOUNCE_TIME)
      );
      setSearchState(updatedSearchState);
  };

  const viewMoreLessConfig = {
    showMore(expanded: boolean) {
      return <React.Fragment>
        <img
          src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812857/icons/${expanded ? 'up' : 'down'}-chevron-black.svg`}
          alt={`${expanded ? 'View less arrow pointing up' : 'View more arrow pointing down'}`}
        />
        {expanded ? 'VIEW LESS' : 'VIEW MORE'}
      </React.Fragment>
    }
  };

  const toggleMobileMenu = ():void => {
    if (!expandMobileFilters) {
      setExpandMobileMenu(true);
      setTimeout(() => { setExpandMobileFilters(true); }, 100)
    } else {
      setExpandMobileFilters(false);
      setTimeout(() => { setExpandMobileMenu(false);}, 1000)
    }
  }

  return <Layout {...state}>
    <InstantSearch
      searchClient={searchClient}
      indexName={`${process.env.REACT_APP_INSTANTSEARCH_DEVICES}`}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <section className="ais-InstantSearch">
        <Container>
          <Row>
            <Col className="ais-InstantSearch">
              <div className="search">
                <CustomSearchBox />
              </div>
              <div className="results">
                <Results>
                  <div className={`left-panel ${expandMobileMenu ? '' : 'd-none'} d-lg-block ${expandMobileFilters ? 'active' : ''}`}>
                    <div id="refinement-functions" className="d-lg-none">
                      <span id="clear-refinements">
                        <ClearRefinements
                          translations={{
                            reset: 'Clear',
                          }}
                        />
                      </span>
                      <span
                        id="apply-refinements"
                        onClick={() => { toggleMobileMenu() }}
                      >
                        Close
                      </span>
                    </div>
                    <div id="refinement-list">
                      <h3 id="refine-title" className="d-none d-lg-block text-lg-center">
                        Filter Devices
                      </h3>
                      <Panel
                        header="Platform"
                        className="refinement-os"
                      >
                        <RefinementList
                          attribute="OS"
                          operator="and"
                          transformItems={(items: any) => orderBy(items, "label", "desc")}
                        />
                      </Panel>
                      <Panel
                        header="Function"
                        className="refinement-function"
                      >
                        <RefinementList
                          attribute="functionality"
                          operator="and"
                          transformItems={(items: any) => orderBy(items, "label", "asc")}
                        />
                      </Panel>
                      <Panel
                        header="Compatibility"
                        className="refinement-compatibility"
                      >
                        {/* <RefinementList
                            attribute="compatibility"
                            operator="and"
                            limit={100}
                            showMore={true}
                            translations={viewMoreLessConfig}
                            transformItems={(items:any) => orderBy(items, "label", "asc")}
                          /> */}
                        <CustomHierarchicalMenu
                          attributes={[
                            'categories.lvl0',
                            'categories.lvl1'
                          ]}
                          limit={30}
                        />
                      </Panel>
                      <Panel
                        header="Features"
                        className="refinement-features"
                      >
                        <RefinementList
                          attribute="features"
                          operator="and"
                          limit={100}
                          showMore={true}
                          translations={viewMoreLessConfig}
                          transformItems={(items: any) => orderBy(items, "label", "asc")}
                        />
                      </Panel>
                      <Panel
                        header="Industry"
                        className="refinement-industry"
                      >
                        <RefinementList
                          attribute="industry"
                          operator="and"
                          transformItems={(items: any) => orderBy(items, "label", "asc")}
                        />
                      </Panel>
                    </div>
                  </div>
                  <div className="right-panel">
                    <div id="banner">
                      <PromoBanner {...searchState.refinementList} />
                    </div>
                    <button
                      id="params"
                      className={`btn btn-${theme.mode ? "secondary" : "light"}`}
                      onClick={() => toggleMobileMenu()}
                    >
                      <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M7 11.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5" />
                      </svg>
                      Filters
                      <CustomRefinements />
                    </button>
                    <div id="hitsLayout" className={isDefaultView ? 'd-none' : undefined}>
                      <div id="hits">
                        <CustomHits />
                      </div>
                      <div id="search-footer">
                        <div id="pagination">
                          <Pagination
                            showFirst={true}
                            showPrevious={true}
                            showNext={true}
                            showLast={true}
                          />
                        </div>
                        <div id="hits-per-page">
                          <HitsPerPage
                            defaultRefinement={9}
                            items={[
                              { value: 9, label: 'Show 9 hits' },
                              { value: 15, label: 'Show 15 hits' },
                              { value: 30, label: 'Show 30 hits' }
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div id="initLayout" className={!isDefaultView ? 'd-none' : undefined}>
                      {state.dbLoaded ?
                        <CustomInitHits dbLoaded={state.dbLoaded} sections={state.sections} /> :
                        <div className="spinner-border d-block mx-auto mt-5" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      }
                    </div>
                  </div>
                </Results>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </InstantSearch>
  </Layout>;
}

const reducer = (state: IDeviceSearch, action: IAction): IDeviceSearch => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PAGE_STATE":
      return {
        ...state,
        dbLoaded: true,
        ...payload
      }
    default:
      return state;
  }
}

export default DeviceSearch;
