import React, {useContext} from 'react';
import {PageContext} from '../../context/PageState';
import DynamicsMarketingForm from '../../utils/DynamicsMarketingForm';

import "./index.scss";

interface IForms {
    type?: string;
}

const Forms: React.FC<IForms> = ({type}) => {
    const { modalForm } = useContext(PageContext);

    const ExternalForm = () => {
        if (!modalForm.iframeSrc) throw new Error("modalForm.iframeSrc must have a string");
        return <iframe
            src={modalForm.iframeSrc}
            width="100%"
            height={modalForm.height + 20 + 'px'}
            className={(!modalForm.visible) ? "d-none" : "d-block"}
            title="Form"
        />
    }

    const DefaultForm = () => <DynamicsMarketingForm>
        <div className="text-center">
            <h4>Get in touch</h4>
            <p className="subheading-text mb-0">
                <span style={{ fontSize: "1rem" }}>
                    For RMAs or support related questions please visit our
                    &nbsp;<a href="https://ipcmobile.com/support/support-form">Support Form</a>.
                </span>
            </p>
        </div>
        <div
            data-form-id='7b692e13-1cd8-ef11-8ee9-6045bdd57e03'
            data-form-api-url='https://public-usa.mkt.dynamics.com/api/v1.0/orgs/bf3426d6-b817-4c2c-986f-2b0cef3ec194/landingpageforms'
            data-cached-form-url='https://assets-usa.mkt.dynamics.com/bf3426d6-b817-4c2c-986f-2b0cef3ec194/digitalassets/forms/7b692e13-1cd8-ef11-8ee9-6045bdd57e03'
        />
    </DynamicsMarketingForm>;

    const TestForm = () => <DynamicsMarketingForm>
        <h4 className="text-center">Test Form</h4>
        <div
            data-form-id='24e3c97b-3e8b-ef11-ac20-7c1e524a7416'
            data-form-api-url='https://public-usa.mkt.dynamics.com/api/v1.0/orgs/bf3426d6-b817-4c2c-986f-2b0cef3ec194/landingpageforms'
            data-cached-form-url='https://assets-usa.mkt.dynamics.com/bf3426d6-b817-4c2c-986f-2b0cef3ec194/digitalassets/forms/24e3c97b-3e8b-ef11-ac20-7c1e524a7416'
        />
    </DynamicsMarketingForm>;

    switch (type?.toLowerCase()) {
        case "newsletter": return <></>
        case "test":
            return <TestForm />;
        case "external": return <ExternalForm />;
        default: return <DefaultForm />;
    }

}

export default Forms