import React, { useEffect } from 'react'

type Props = {
    children: React.ReactNode
}

const DynamicsMarketingForm: React.FC<Props> = props => {

    useEffect(() => {
        const s = document.createElement('script');
        s.src = "https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/usa/FormLoader/FormLoader.bundle.js";
        const t = document.getElementsByTagName('script')[0];
        if (!document.querySelectorAll(`[src*="${s.src}"]`).length) {
            if (t.parentNode) t.parentNode.insertBefore(s, t);
            document.documentElement.lang = navigator.language;
        }
        return () => t.remove();
    }, [])

    return props.children;
}

export default DynamicsMarketingForm